import { ComponentProps } from 'react';
import styled from 'styled-components';

import { IDEALIST_UTM_PARAMS } from 'utils/constants/general/idealistUtmParams';
import { hasUTMParam } from 'utils/url/hasUTMParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import { pathWithParams } from 'utils/url/pathWithParams';

import { Button } from './Button';

type Props = ComponentProps<typeof Button> & {
  href?: string;
  target?: string;
  rel?: string;
};

const Link = styled.a<{ disabled?: boolean }>`
  color: inherit;
  text-decoration: none;

  &:hover,
  &:active,
  &:link {
    color: inherit;
    text-decoration: none;
  }
`;

export function AnchorButton({
  // common props from Button:
  'aria-label': ariaLabel,
  'data-qa-id': dataQaId,
  'data-qa-submitting': dataQaSubmitting,
  children,
  className,
  disabled,
  fullWidth,
  noPadding,
  icon,
  iconPosition,
  id,
  onBlur,
  onClick,
  onFocus,
  role,
  size,
  tabIndex,
  title,
  type,
  variant,
  // props unique to AnchorButton:
  href,
  rel,
  target,
}: Props) {
  return (
    <Link
      href={
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        isExternalURL(href) && !hasUTMParam(href)
          ? pathWithParams(href, IDEALIST_UTM_PARAMS)
          : href
      }
      rel={rel}
      target={target}
      // commmon props:
      aria-label={ariaLabel}
      data-qa-id={dataQaId}
      data-qa-submitting={dataQaSubmitting}
      disabled={disabled}
      id={id}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      <Button
        className={className}
        disabled={disabled}
        fullWidth={fullWidth}
        icon={icon}
        iconPosition={iconPosition}
        noPadding={noPadding}
        onBlur={onBlur}
        onFocus={onFocus}
        role={role}
        size={size}
        tabIndex={tabIndex}
        title={title}
        type={type}
        variant={variant}
      >
        {children}
      </Button>
    </Link>
  );
}
