import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import { FormUsageContext } from 'components/Form/Form';
import { Link, RouterLink } from 'components/Link';
import { trackClicked } from 'utils/analytics/track/trackClicked';

type Props = {
  formUsageContext: FormUsageContext;
  children: ReactNode;
  email: string;
  signUpOverrideFn?: () => void;
  showSignupModal: (email: string) => void;
  getSignupUrl: (email: string) => LinkProps['to'];
};

export function LoginFormSignupLink({
  formUsageContext,
  children,
  email,
  signUpOverrideFn,
  showSignupModal,
  getSignupUrl,
}: Props) {
  if (signUpOverrideFn) {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        href="#"
        data-qa-id="login-form-signup-link"
        onClick={(e) => {
          e.preventDefault();
          trackClicked('Sign Up');
          signUpOverrideFn();
        }}
      >
        {children}
      </Link>
    );
  }

  if (formUsageContext === 'modal') {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        href="#"
        data-qa-id="login-form-signup-link"
        onClick={(e) => {
          e.preventDefault();
          trackClicked('Sign Up');
          showSignupModal(email);
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <RouterLink
      to={getSignupUrl(email)}
      data-qa-id="login-form-signup-link"
      onClick={() => {
        trackClicked('Sign Up');
      }}
    >
      {children}
    </RouterLink>
  );
}
