import { ReactNode } from 'react';
import { LinkProps } from 'react-router-dom';

import { FormUsageContext } from 'components/Form/Form';
import { Link, RouterLink } from 'components/Link';

type Props = {
  formUsageContext: FormUsageContext;
  children: ReactNode;
  email: string;
  showForgotPasswordModal: (data: { email: string }) => void;
  getForgotPasswordUrl: (data: { email: string }) => LinkProps['to'];
};

export function LoginFormForgotPasswordLink({
  formUsageContext,
  children,
  email,
  showForgotPasswordModal,
  getForgotPasswordUrl,
}: Props) {
  if (formUsageContext === 'modal') {
    return (
      // TODO: Fix this the next time the file is edited.
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <Link
        href="#"
        onClick={(e) => {
          e.preventDefault();
          showForgotPasswordModal({
            email,
          });
        }}
      >
        {children}
      </Link>
    );
  }

  return (
    <RouterLink
      to={getForgotPasswordUrl({
        email,
      })}
    >
      {children}
    </RouterLink>
  );
}
