import { ReactNode } from 'react';

import { Box } from 'components/Box';
import { Divider } from 'components/Divider';
import { Form, FormUsageContext } from 'components/Form/Form';
import {
  FormContextValue,
  FormSubmitFunction,
} from 'components/Form/FormContext';
import { GeneralError } from 'components/Form/GeneralError';
import { InputField } from 'components/Form/InputField';
import { RecaptchaField } from 'components/Form/RecaptchaField';
import { SubmitButton } from 'components/Form/SubmitButton/SubmitButton';
import { Link, RouterLink } from 'components/Link';
import { AppleOAuthButton } from 'components/OAuthButton/AppleOAuthButton';
import { FacebookOAuthButton } from 'components/OAuthButton/FacebookOAuthButton';
import { GoogleOAuthButton } from 'components/OAuthButton/GoogleOAuthButton';
import { BodySmall } from 'components/Text/BodySmall';
import { trackClicked } from 'utils/analytics/track/trackClicked';

import { EmailSignupFormValues } from './types/EmailSignupFormValues';

type Props = {
  analyticsTitle: string;
  usageContext: FormUsageContext;
  initialValues: Partial<EmailSignupFormValues>;
  showLoginModal: (email: string | undefined) => void;
  getLoginUrlAndState: (email: string | undefined) => {
    to: string;
    state: Record<string, unknown>;
  };
  getThirdPartyLoginUrl: (
    arg0: 'facebook' | 'google' | 'apple',
  ) => Promise<string> | string;
  loginOverrideFn?: () => void;
  onSubmit: FormSubmitFunction<EmailSignupFormValues>;
  compactView?: boolean;
  hideLoginBlock?: boolean;
  customButtonText?: string;
  requirePassword?: boolean;
};

export function EmailSignupForm({
  analyticsTitle,
  usageContext,
  initialValues,
  showLoginModal,
  getLoginUrlAndState,
  getThirdPartyLoginUrl,
  loginOverrideFn,
  onSubmit,
  compactView,
  hideLoginBlock,
  customButtonText,
  requirePassword,
}: Props) {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/function-component-definition, react/no-unstable-nested-components
  const LoginLink = ({
    email,
    ...props
  }: {
    children?: ReactNode;
    email?: string;
  }) => {
    if (loginOverrideFn) {
      return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          href="#"
          data-qa-id="email-form-login-link"
          onClick={(e) => {
            e.preventDefault();
            trackClicked('Log In');
            loginOverrideFn();
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }

    if (usageContext === 'modal' && showLoginModal) {
      return (
        // TODO: Fix this the next time the file is edited.
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <Link
          href="#"
          data-qa-id="email-form-login-link"
          onClick={(e) => {
            e.preventDefault();
            trackClicked('Log In');
            showLoginModal(email);
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...props}
        />
      );
    }
    const loginUrlAndState = getLoginUrlAndState(email);
    return (
      <RouterLink
        to={loginUrlAndState.to}
        state={loginUrlAndState.state}
        data-qa-id="email-form-login-link"
        onClick={() => {
          trackClicked('Log In');
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...props}
      />
    );
  };

  return (
    <Form<EmailSignupFormValues, FormContextValue<EmailSignupFormValues>>
      analyticsTitle={analyticsTitle}
      usageContext={usageContext}
      data-qa-id="signup-email-form"
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ values: { email } }) => (
        <>
          {!hideLoginBlock && (
            <BodySmall mb={[16, null, 16]} textAlign="center">
              {getText('Already have an account?')}{' '}
              <LoginLink email={email}>{getText('Log in')}</LoginLink>
            </BodySmall>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 12,
              marginBottom: 30,
            }}
          >
            <GoogleOAuthButton getUrl={() => getThirdPartyLoginUrl('google')} />
            <FacebookOAuthButton
              getUrl={() => getThirdPartyLoginUrl('facebook')}
            />
            <AppleOAuthButton getUrl={() => getThirdPartyLoginUrl('apple')} />
          </div>

          {!compactView && <Divider marginTop={24} marginBottom={24} />}

          <GeneralError />

          <InputField
            name="email"
            label={getText('Or use your email:')}
            type="email"
            placeholder={getText('casey.smith@example.com')}
          />

          {requirePassword && (
            <InputField
              name="password"
              type="password"
              label={getText('Password')}
              placeholder={getText('Password')}
            />
          )}

          {(!compactView || email) && <RecaptchaField />}

          <Box mt={[16, null, 40]} display="flex" justifyContent="flex-end">
            <SubmitButton fullWidth size="large">
              {customButtonText || getText('Sign Up')}
            </SubmitButton>
          </Box>
        </>
      )}
    />
  );
}
