import { ReactNode } from 'react';

import { getClassName, textStyles } from 'theme/theme';

import { AnalyticsData } from './AnalyticsData';
import { StyledRichText } from './RichText.styled';

type Props = {
  tag?: string;
  styleAs?: keyof typeof textStyles;
  analyticsData?: AnalyticsData;

  simple?: boolean;
  small?: boolean;
  normalize?: boolean;
  html?: string;
  dangerouslySetInnerHTML?: { __html: string };
  children?: ReactNode;
  className?: string;
  noMargin?: boolean;
  textAlignCenter?: boolean;
  color?: string;
};

export function RichText({
  tag,
  styleAs,
  analyticsData,
  simple,
  small,
  normalize,
  html,
  dangerouslySetInnerHTML,
  children,
  className,
  noMargin,
  textAlignCenter,
  color,
}: Props) {
  const cleanHtml =
    html?.replace(
      /<(p|h[1-6]|blockquote)>(<br\/?>)?<\/(p|h[1-6]|blockquote)>/g,
      '',
    ) || '';

  return (
    <StyledRichText
      tag={tag}
      styleAs={styleAs}
      analyticsData={analyticsData}
      dangerouslySetInnerHTML={
        dangerouslySetInnerHTML ||
        (cleanHtml && { __html: cleanHtml }) ||
        undefined
      }
      className={getClassName(className || '', ['rich-text'])}
      $small={Boolean(small)}
      $simple={Boolean(simple)}
      $normalize={Boolean(normalize)}
      $textAlignCenter={Boolean(textAlignCenter)}
      $noMargin={Boolean(noMargin)}
      $color={color}
    >
      {children}
    </StyledRichText>
  );
}
