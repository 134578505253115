import queryString from 'query-string';
import { Component } from 'react';
import { ConnectedProps, connect } from 'react-redux';
import { sprintf } from 'sprintf-js';

import { ApiSavedSearchUnsaved } from 'api/savedSearch/types/ApiSavedSearch';
import { logIn } from 'api/userEnvironment/apiUserEnvironment';
import { ApiUser } from 'api/userEnvironment/types/ApiUserEnvironment';
import { FormUsageContext } from 'components/Form/Form';
import { FormSubmitFunction } from 'components/Form/FormContext';
import { LoginForm, LoginFormValues } from 'components/LoginForm';
import { cmsApiSubscribeToSubsite } from 'modules/cms/api/cmsApiSubscribeToSubsite';
import { CmsApiSubscribe } from 'modules/cms/api/types/CmsApiSubscribe';
import { signupRoute } from 'routing/routes';
import {
  saveListing,
  saveSearch,
  updateUserEnvironment,
} from 'store/ducks/userEnvironment.actions';
import { trackLogin } from 'utils/analytics/track/trackLogin';
import { configureTrackJs } from 'utils/configureTrackJs';
import { LOGIN_PROVIDER_NAMES } from 'utils/constants/general/loginProviderNames';
import { createCookie, deleteCookie } from 'utils/cookies';
import { getThirdPartyLoginUrl } from 'utils/getThirdPartyLoginUrl';
import { getRelativeURL } from 'utils/url/getRelativeURL';
import { hasParam } from 'utils/url/hasParam';
import { isExternalURL } from 'utils/url/isExternalURL';
import { RouteComponentProps, withRouter } from 'utils/withRouter';
import {
  clearFlashMessages,
  pushFlashMessage,
} from 'zustand-stores/flashMessagesStore';
import { hideModal, showModal } from 'zustand-stores/modalStore';

type OwnProps = {
  analyticsTitle: string;
  usageContext: FormUsageContext;
  redirectTo?: string | null | undefined;
  // @TODO - we only want `redirectTo` or `redirectUrl`
  // Currently, `redirectUrl` refers to the `redirectUrl`
  // that comes from the CmsSignupBlock
  redirectUrl?: string | null | undefined;
  email?: string | null | undefined;
  savedListing?: { id: string; type: string; name: string };
  savedSearch?: ApiSavedSearchUnsaved;
  cmsSubscribe?: CmsApiSubscribe;
  subscribedSubsite?: string | null | undefined;
  inviteId?: string | null | undefined;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onLogin: ((...args: Array<any>) => any) | null | undefined;
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  signUpOverrideFn?: (...args: Array<any>) => any;
  centered?: boolean;
  initialWarning?: string | null | undefined;
  compactView?: boolean;
  uncloseable?: boolean;
};

type Props = OwnProps & RouteComponentProps & PropsFromRedux;

type State = {
  thirdPartyLoginError: string | null | undefined;
  initialWarning: string | null | undefined;
};

class UnconnectedLoginFormContainer extends Component<Props, State> {
  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/state-in-constructor
  state = {
    thirdPartyLoginError: null,
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line react/destructuring-assignment
    initialWarning: this.props.initialWarning,
  };

  isMounted = false;

  // TODO: Fix this the next time the file is edited.
  // eslint-disable-next-line react/sort-comp
  get initialValues() {
    const { props } = this;
    return {
      email: props.email || '',
      password: '',
    };
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static getDerivedStateFromProps(nextProps: any) {
    const { error, provider } = queryString.parse(nextProps.location.search);
    if (!error) return null;
    // @ts-expect-error TS(2538): Type '(string | null)[]' cannot be used as an inde... Remove this comment to see the full error message
    const providerName = LOGIN_PROVIDER_NAMES[provider];
    let errorMessage;

    if (error === 'EXISTING_ACCOUNT' && providerName) {
      // TRANSLATORS: error message when user tries to log in using
      // TRANSLATORS: Facebook or Google but they already had an account
      // TRANSLATORS: that did not use that login provider
      errorMessage = sprintf(
        getText(
          'Please log in first to associate that %(provider)s ' +
            'account to your existing Idealist account',
        ),
        {
          provider: providerName,
        },
      );
    }

    if (error === 'MISMATCHED_ACCOUNTS' && providerName) {
      // TRANSLATORS: User tried to link Facebook/Google account, but
      // TRANSLATORS: it was already used by another Idealist acccount
      errorMessage = sprintf(
        getText(
          'That %(provider)s account is already associated with ' +
            'another Idealist account',
        ),
        {
          provider: providerName,
        },
      );
    }

    if (error === 'FAILED_OAUTH_LOGIN') {
      // TRANSLATORS: user used 3rd party authentication like Google, but it failed
      errorMessage = getText(
        'Authentication did not work as expected, please try again.',
      );
    }

    if (error === 'USER_FROZEN') {
      // TRANSLATORS: user tried to login, but user is frozen
      errorMessage = getText(
        'Your account has been frozen for violating our Terms of Service.',
      );
    }

    if (errorMessage) {
      // remove error and provider params from URL
      const params = queryString.parse(nextProps.location.search);
      delete params.error;
      delete params.provider;
      nextProps.navigate(
        {
          pathname: nextProps.location.pathname,
          search: queryString.stringify(params),
        },
        { replace: true },
      );
      return {
        thirdPartyLoginError: errorMessage,
      };
    }

    return null;
  }

  showSignupModal = (email: string) => {
    const { props } = this;
    showModal('SIGNUP', {
      // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      redirectTo: props.redirectTo,
      savedListing: props.savedListing,
      savedSearch: props.savedSearch,
      cmsSubscribe: props.cmsSubscribe,
      // @ts-expect-error TS(2322): Type 'string | null | undefined' is not assignable... Remove this comment to see the full error message
      subscribedSubsite: props.subscribedSubsite,
      email,
      uncloseable: props.uncloseable,
    });
  };

  getSignupUrl = (email: string) => {
    const {
      cmsSubscribe,
      redirectTo,
      inviteId,
      savedListing,
      savedSearch,
      subscribedSubsite,
    } = this.props;
    return {
      pathname: signupRoute.with({ inviteId }),
      state: {
        redirectTo,
        email,
        savedListing,
        savedSearch,
        cmsSubscribe,
        subscribedSubsite,
      },
    };
  };

  // eslint-disable-next-line class-methods-use-this
  showForgotPasswordModal = (state: { email: string }) => {
    showModal('FORGOT_PASSWORD', state);
  };

  // eslint-disable-next-line class-methods-use-this
  getForgotPasswordUrl = (state: { email: string }) => ({
    pathname: '/forgot-password',
    state,
  });

  getThirdPartyLoginUrl = (provider: 'facebook' | 'google' | 'apple') => {
    const {
      redirectTo,
      inviteId,
      savedListing,
      savedSearch,
      cmsSubscribe,
      subscribedSubsite,
    } = this.props;
    return getThirdPartyLoginUrl({
      provider,
      redirectTo,
      inviteId,
      savedListing,
      savedSearch,
      cmsSubscribe,
      subscribedSubsite,
    });
  };

  submitLogin: FormSubmitFunction<LoginFormValues> = ({
    values,
    handleError,
  }) => {
    const { props } = this;
    deleteCookie('userDismissedNotificationPreferencesDialog');
    this.setState({
      initialWarning: null,
    });
    const loginInfo = {};
    Object.assign(loginInfo, values);

    if (props.subscribedSubsite) {
      // @ts-expect-error TS(2339): Property 'subscribeSubsiteId' does not exist on ty... Remove this comment to see the full error message
      loginInfo.subscribeSubsiteId = props.subscribedSubsite;
    }

    let user: ApiUser | null = null;
    logIn(loginInfo)
      .then((userEnvironment) => {
        props.updateUserEnvironment(userEnvironment);
        user = userEnvironment.user;
        trackLogin(user, 'Email Password');
        configureTrackJs(user.id);

        if (user.locale) {
          createCookie('_LOCALE_', user.locale, 1825);
        }

        hideModal();
        clearFlashMessages();

        const isSearchPage =
          hasParam(props.location.search, 'q') ||
          hasParam(props.location.search, 'pq');

        const hasTemporaryStorage = Boolean(
          props.redirectUrl?.includes('tmpId'),
        );

        if (props.redirectUrl) {
          if (isExternalURL(props.redirectUrl)) {
            window.location.href = props.redirectUrl;
          } else {
            props.navigate(getRelativeURL(props.redirectUrl), {
              replace: isSearchPage || hasTemporaryStorage,
            });
          }
        }
      })
      .then(() => {
        if (props.savedListing) {
          const { type, name, id } = props.savedListing;
          return props.saveListing(id, type).then(() => {
            if (type === 'ORG') {
              pushFlashMessage({
                type: 'SAVE_ORG',
                data: {
                  orgName: name,
                },
              });
            }
          });
        }

        if (props.savedSearch) {
          return props.saveSearch(props.savedSearch);
        }

        if (props.subscribedSubsite) {
          return cmsApiSubscribeToSubsite(props.subscribedSubsite).then(() => {
            pushFlashMessage({ type: 'SUBSCRIBED_SUBSITE' });
            showModal('SUBSCRIBED_TO_SUBSITE', {});
          });
        }
      })
      .then(() => {
        if (props.onLogin) {
          props.onLogin(user);
        }
      })
      .catch(handleError)
      .finally(() => {
        if (this.isMounted) {
          this.setState({
            thirdPartyLoginError: null,
          });
        }
      });
  };

  render() {
    const {
      analyticsTitle,
      usageContext,
      signUpOverrideFn,
      centered,
      compactView,
    } = this.props;
    const { thirdPartyLoginError, initialWarning } = this.state;

    return (
      <LoginForm
        analyticsTitle={analyticsTitle}
        usageContext={usageContext}
        initialValues={this.initialValues}
        thirdPartyLoginError={thirdPartyLoginError}
        showSignupModal={this.showSignupModal}
        getSignupUrl={this.getSignupUrl}
        signUpOverrideFn={signUpOverrideFn}
        showForgotPasswordModal={this.showForgotPasswordModal}
        getForgotPasswordUrl={this.getForgotPasswordUrl}
        getThirdPartyLoginUrl={this.getThirdPartyLoginUrl}
        submitLogin={this.submitLogin}
        centered={centered}
        initialWarning={initialWarning}
        compactView={compactView}
      />
    );
  }
}

const connector = connect(null, {
  updateUserEnvironment,
  saveListing,
  saveSearch,
});

type PropsFromRedux = ConnectedProps<typeof connector>;

export const LoginFormContainer = withRouter(
  connector(UnconnectedLoginFormContainer),
);
